import { ListItem } from "@mui/material";

type ListItemTopBorderType = {
  children: React.ReactNode;
};

export const ListItemTopBorder = (props: ListItemTopBorderType) => {
  return (
    <ListItem disablePadding sx={{ borderTop: 1, borderColor: "lightGray" }}>
      {props.children}
    </ListItem>
  );
};
