import { getIdToken } from "./getIdToken";
import { showErrorMessage } from "components/showErrorMessage";

type HeadersType = Record<string, string>;

const isRecord = (values: unknown): values is HeadersType => {
  return typeof values === "object";
};

const setAuthorization = async (
  options: RequestInit = {},
): Promise<RequestInit> => {
  const token = await getIdToken();

  if (!options) {
    options = { method: "GET", headers: {} };
  }

  const authorizationValue = `Bearer ${token}`;

  if (!options.headers) {
    options.headers = { Authorization: authorizationValue };
  } else if (isRecord(options.headers)) {
    options.headers["Authorization"] = authorizationValue;
  }

  return options;
};

export const fetchWithAlert = async <T>(
  resource: RequestInfo | URL,
  options?: RequestInit,
): Promise<T> => {
  try {
    const fetchOptions = await setAuthorization(options);
    const response = await fetch(resource, fetchOptions);

    if (!response.ok) {
      throw new Error(`${response.text()}`);
    }

    return await response.json();
  } catch (error) {
    showErrorMessage(error);
    throw error;
  }
};
