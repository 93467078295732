import React from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DiseaseTrendGraphPage } from "./DiseaseTrendGraphPage"; // グラフコンポーネント
import { DiseaseTrendDataPage } from "./DiseaseTrendDataPage"; // データコンポーネント
import { DiseaseTrendItemContext } from "contexts/DiseaseTrendItemContext";
import { useQueryDiseaseTrendItemsAll, useQueryStatusHandler } from "hooks";

const useQueryParam = () => {
  return new URLSearchParams(useLocation().search);
};

export const DiseaseTrendMainPage: React.FC = () => {
  const { isLoading, isError, data, isPending } =
    useQueryDiseaseTrendItemsAll();
  const diseaseCd = useQueryParam().get("trend");
  const theme = useTheme();
  const isPC = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));

  //疾患トレンド
  const diseaseTrendItem = data?.items?.find((group) => {
    return group.group_cd === diseaseCd;
  });

  const message = useQueryStatusHandler({
    isLoading,
    isPending,
    isError,
    paramsData: [data, diseaseTrendItem],
    noDataMessage: `指定のコード(${diseaseCd})は存在しません。`,
  });
  if (message) return message;

  const chartHeight = isPC ? "75vh" : isTablet ? "70vh" : "65vh";
  const item = diseaseTrendItem!;

  return (
    <DiseaseTrendItemContext.Provider value={{ diseaseTrendItem: item }}>
      <Grid container spacing={2}>
        {isPC && (
          <Grid lg={6}>
            <DiseaseTrendDataPage />
          </Grid>
        )}
        <Grid lg={isPC ? 6 : 12} sm={12} sx={{ height: chartHeight }}>
          <DiseaseTrendGraphPage />
        </Grid>
      </Grid>
    </DiseaseTrendItemContext.Provider>
  );
};
