import { useContext } from "react";
import { Typography } from "@mui/material";
import { GraphLatestDataTable } from "../components/GraphLatestDataTable";
import { useQueryLatest, useQueryStatusHandler } from "hooks";
import { tableCellColor } from "functions/tableCellColor";
import { DiseaseTrendItemContext } from "contexts/DiseaseTrendItemContext";
import { DiseaseTrendDataRowType } from "types/diseaseTrendDataRowType";

export const DiseaseTrendDataPage = () => {
  const { isLoading, isPending, isError, data } = useQueryLatest();
  const { diseaseTrendItem } = useContext(DiseaseTrendItemContext);

  const message = useQueryStatusHandler({
    isLoading,
    isPending,
    isError,
    paramsData: [data, data?.items[0]],
  });
  if (message) return message;

  const latestItems = data!.items[0]!;
  const kdate = latestItems.kdate;
  const facility = latestItems.req_name;

  const rows: DiseaseTrendDataRowType[] = latestItems.items
    .filter((item) => !item.item_param.split(",").includes("kensinonly:true"))
    .map((item) => {
      return {
        groupColor: item.group_param.split(":")[1],
        name: item.item_name,
        result: item.result,
        cellColor: tableCellColor(item.abnormality_flg),
        highlighted: diseaseTrendItem.items
          .map((item) => item.item_cd)
          .includes(item.item_cd),
      };
    });

  return (
    <>
      <Typography variant="h4" gutterBottom>
        最新データ
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        検査日：{kdate}
        <br />
        実施施設：{facility}
      </Typography>
      <GraphLatestDataTable rowData={rows} />
    </>
  );
};
