import { useContext, useEffect } from "react";
import { LoadingSpinnerContext } from "contexts/LoadingSpinnerContext";
import { Typography } from "@mui/material";

type QueryStatusHandlerProps = {
  isLoading: boolean;
  isError: boolean;
  isPending: boolean;
  errorMessage?: string;
  noDataMessage?: string;
  paramsData?: (object | undefined)[];
};

const hasData = (paramsData: (object | undefined)[]) => {
  return paramsData.every((data) => !!data);
};

const wrapperTypography = (message?: string) => {
  return <Typography variant="body1">{message}</Typography>;
};

export const useQueryStatusHandler = ({
  isLoading = false,
  isError = false,
  isPending = false,
  errorMessage = "データの読み込みに失敗しました。",
  noDataMessage = "データがありません。",
  paramsData = [],
}: QueryStatusHandlerProps) => {
  const { setIsLoading } = useContext(LoadingSpinnerContext);

  useEffect(() => setIsLoading(isLoading), [isLoading]);

  if (isLoading || isPending) return wrapperTypography();
  if (isError) return wrapperTypography(errorMessage);
  if (hasData(paramsData)) return null;

  return wrapperTypography(noDataMessage);
};
