import {
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { Footer } from "./Footer";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

let theme = createTheme({
  palette: {
    primary: {
      main: "#ec4079",
    },
    secondary: {
      main: "#40ecb3",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      "Noto Sans JP",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    allVariants: {
      color: "#1A1E23",
    },
  },
});

theme = responsiveFontSizes(theme);

export const CommonLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container
        maxWidth="xl"
        sx={{ my: 4, minHeight: "calc(100svh - 196.8px)" }}
      >
        <Outlet />
      </Container>
      <Footer />
    </ThemeProvider>
  );
};
