import { AccountSettings } from "@aws-amplify/ui-react";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function ChangePasswordPage() {
  const navigate = useNavigate();
  const handleSuccess = () => {
    alert("パスワードが正常に変更されました");
    navigate("/latest-data");
  };

  const handleError = (error: Error) => {
    alert(`エラーが発生しました: ${error.message}`);
  };

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        パスワード変更
      </Typography>

      <Box sx={{ maxWidth: 400, mx: "auto" }}>
        <AccountSettings.ChangePassword
          onSuccess={handleSuccess}
          onError={handleError}
          displayText={{
            currentPasswordFieldLabel: "現在のパスワード",
            newPasswordFieldLabel: "新しいパスワード",
            confirmPasswordFieldLabel: "新しいパスワードの確認",
            updatePasswordButtonText: "パスワードを更新",
          }}
        />
      </Box>
    </>
  );
}
