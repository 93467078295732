import { BrowserRouter as Router } from "react-router-dom";
import { StrictMode, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "./components/Auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRoutes from "./components/AppRoutes";
import { LoadingSpinnerContext } from "./contexts/LoadingSpinnerContext";
import LoadingSpinner from "components/LoadingSpinner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [isLoading, setIsLoading] = useState(false);

  if (authStatus === "configuring") return null;

  return (
    <StrictMode>
      <Router>
        {authStatus !== "authenticated" ? (
          <Auth />
        ) : (
          <QueryClientProvider client={queryClient}>
            <LoadingSpinnerContext.Provider value={{ isLoading, setIsLoading }}>
              <AppRoutes />
              <LoadingSpinner />
            </LoadingSpinnerContext.Provider>
          </QueryClientProvider>
        )}
      </Router>
    </StrictMode>
  );
};
