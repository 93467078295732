import FiberNewIcon from "@mui/icons-material/FiberNew";
import UpdateIcon from "@mui/icons-material/Update";
import TimelineIcon from "@mui/icons-material/Timeline";

type SideMenuItem = {
  id: string;
  title: string;
  icon: JSX.Element;
  link: string;
};

export const SideMenuData: SideMenuItem[] = [
  {
    id: "latest",
    title: "最新データ",
    icon: <FiberNewIcon />,
    link: "/latest-data",
  },
  {
    id: "time-series",
    title: "時系列データ",
    icon: <UpdateIcon />,
    link: "/time-series-data",
  },
  {
    id: "graph",
    title: "グラフ",
    icon: <TimelineIcon />,
    link: "",
  },
];
