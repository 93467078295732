import { FC } from "react";
import { Stack, SxProps, Theme, Typography } from "@mui/material"; // Import the Typography component from the appropriate package
import { useQueryPersonInfomation } from "hooks/useQueryPersonInfomation";

type Props = {
  sx?: SxProps<Theme>;
};

export const HeaderPersonInfo: FC<Props> = ({ sx }) => {
  const { isLoading, isError, data } = useQueryPersonInfomation();

  if (isLoading) return null;
  if (isError) return null;
  if (!data) return null;

  return (
    <Typography sx={sx} color="inherit" component="div">
      <Stack direction="row" spacing={2} useFlexGap>
        <Typography color="inherit">ID：{data.item.person_cd}</Typography>
        <Typography color="inherit">
          生年月日：{data.item.birthday_date}
        </Typography>
      </Stack>
    </Typography>
  );
};
