export const tableCellColor = (abnormality_flg: string) => {
  switch (abnormality_flg) {
    case "1": // NOTE: パニックハイ
      return "#ff6666";
    case "2": // NOTE: ハイ
      return "#FFCCCC";
    case "3": // NOTE: ロー
      return "#ffffc1";
    case "4": // NOTE: パニックロー
      return "#FFFF00";
    default:
      return "white";
  }
};
