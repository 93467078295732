import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  TooltipItem,
  ChartDataset,
  Plugin,
} from "chart.js";
import { useQueryTimeSeries, useQueryStatusHandler } from "hooks";
import { Box } from "@mui/material";
import { DiseaseTrendItemContext } from "contexts/DiseaseTrendItemContext";

// Chart.jsのコンポーネントを登録
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
);

// カスタムデータセット型を定義
interface CustomChartDataset extends ChartDataset<"line"> {
  originalValues: { itemName: string; value: number; abnormaly: string }[];
}

// 指定した値の位置を計算する関数
const CalculatePoint = (
  start: number,
  end: number,
  scale: number,
  value: number,
): number => {
  const range = Math.abs(end - start);
  let pos = Math.abs(value - start) / (range / scale);
  pos = Math.min(pos, scale);
  pos = Math.max(pos, 0);
  return pos;
};

// グラフ係数
const memoriSize = 10;
const halfMemoriCount = 6;
const normalHighYValue = 10;
const normalLowYValue = -10;
const graphColor = "#fffdf6";
const normalColor = "#e3f2e1";
// 最大値・最小値を基準値の何倍に設定するか
const maxRate = 2;

export const DiseaseTrendGraphPage: React.FC = () => {
  const { diseaseTrendItem } = useContext(DiseaseTrendItemContext);
  const { isLoading, isError, data, isPending } = useQueryTimeSeries();

  const message = useQueryStatusHandler({
    isLoading,
    isPending,
    isError,
    paramsData: [data],
  });
  if (message) return message;

  //時系列から疾患トレンドのアイテムを抽出
  const timeSeriesData = data!;
  const timeSeries = timeSeriesData.items
    .filter((kdateRecord) => {
      return kdateRecord.items.some((item) => {
        return diseaseTrendItem.items.some((trendItem) => {
          return trendItem.item_cd === item.item_cd;
        });
      });
    })
    .sort((a, b) => new Date(a.kdate).getTime() - new Date(b.kdate).getTime());
  //グラフのX用時系列の日付を取得
  const labels = timeSeries.map((kdateRecord) => kdateRecord.kdate);

  const datasets: CustomChartDataset[] = [];
  diseaseTrendItem.items.forEach((trendItem) => {
    const itemCd = trendItem.item_cd;
    const values: number[] = [];
    const originalValues: {
      itemName: string;
      value: number;
      abnormaly: string;
    }[] = []; // ホバー時に表示する元の値を保存するオブジェクト配列
    timeSeries.forEach((kdateRecord) => {
      const item = kdateRecord.items.find((item) => {
        return item.item_cd === itemCd;
      });
      const value = item ? parseFloat(item?.result) : null;
      if (value === null) {
        values.push(NaN);
        originalValues.push({ itemName: "", value: NaN, abnormaly: "" }); // 元の値を保存
        return;
      }

      const normalItem = timeSeriesData.rowHeader.find(
        (row) => row.item_cd === itemCd,
      );
      const high = +(normalItem?.high || 24.9);
      const low = +(normalItem?.low || 18.5);

      const maxHigh = high + Math.abs(high - low) * maxRate;
      const maxLow = low - Math.abs(high - low) * maxRate;

      let y = 0;
      if (value > high) {
        y = CalculatePoint(high, maxHigh, 90, value);
        y =
          memoriSize +
          Math.sin((Math.PI / 180) * y) * (halfMemoriCount * memoriSize);
      } else if (value < low) {
        y = CalculatePoint(low, maxLow, 90, value);
        y =
          -memoriSize -
          Math.sin((Math.PI / 180) * y) * (halfMemoriCount * memoriSize);
      } else {
        y = CalculatePoint(low, high, memoriSize * 2, value);
        y = -memoriSize + y;
      }

      values.push(y);
      let abnormalyText = "正常";
      switch (item?.abnormality_flg) {
        case "1":
          abnormalyText = "大幅に高い";
          break;
        case "2":
          abnormalyText = "高い";
          break;
        case "3":
          abnormalyText = "低い";
          break;
        case "4":
          abnormalyText = "大幅に低い";
          break;
        default:
          break;
      }
      originalValues.push({
        itemName: trendItem.item_name,
        value: value,
        abnormaly: abnormalyText,
      }); // 元の値を保存
    });
    const param = trendItem.param.split(",");
    const color =
      param.find((p) => p.startsWith("color"))?.split(":")[1] ||
      "rgba(255, 99, 132, 1)";
    const name =
      param.find((p) => p.startsWith("name"))?.split(":")[1] ||
      trendItem.item_name;

    datasets.push({
      label: name,
      data: values,
      borderWidth: 4,
      borderColor: color,
      backgroundColor: "transparent",
      spanGaps: true, // 欠けているデータの間に線を引き続ける
      originalValues: originalValues, // カスタムプロパティとして元の値を保存
    } as CustomChartDataset);
  });

  // 背景色プラグイン
  const backgroundColorPlugin: Plugin<"line"> = {
    id: "backgroundColorPlugin",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      const width = chartArea.right - chartArea.left;
      const height = chartArea.bottom - chartArea.top;

      ctx.save();

      // グラフ全体に背景色を設定
      ctx.fillStyle = graphColor; // グラフ全体の背景色
      ctx.fillRect(chartArea.left, chartArea.top, width, height);

      // 背景色を塗る範囲を定義（例としてY軸の特定の範囲に背景色を追加）
      const yScale = chart.scales["y"];

      const yMin = yScale.getPixelForValue(normalLowYValue);
      const yMax = yScale.getPixelForValue(normalHighYValue);

      ctx.fillStyle = normalColor;
      ctx.fillRect(
        chartArea.left,
        yMax,
        chartArea.right - chartArea.left,
        yMin - yMax,
      );

      ctx.restore();
    },
  };

  const linedata: ChartData<"line"> = {
    labels: labels,
    datasets: datasets,
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: diseaseTrendItem.group_name,
        font: {
          size: 30, // フォントサイズを指定
          family: "Arial", // フォントファミリーを指定
          weight: "bold", // フォントウェイトを指定
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<"line">) {
            const dataset = context.dataset as CustomChartDataset;
            const originalValue = dataset.originalValues[context.dataIndex];
            return originalValue
              ? ` ${originalValue.itemName}  値：${originalValue.value}  判定：${originalValue.abnormaly}`
              : "";
          },
        },
      },
    },
    scales: {
      y: {
        suggestedMax: memoriSize + memoriSize * halfMemoriCount,
        suggestedMin: -memoriSize - memoriSize * halfMemoriCount,
        ticks: {
          stepSize: memoriSize,
          callback: function (value) {
            switch (value) {
              case normalHighYValue:
                return "high";
              case normalLowYValue:
                return "low";
              default:
                return "";
            }
          },
        },
      },
    },
    maintainAspectRatio: false, // グラフの縦横比を維持しない
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Line
        data={linedata}
        options={options}
        plugins={[backgroundColorPlugin]}
      />
    </Box>
  );
};
