import { Routes, Route, Navigate } from "react-router-dom";
import { CommonLayout } from "./CommonLayout";
import { ChangePasswordPage } from "pages/ChangePasswordPage";
import { LatestDataPage } from "pages/LatestDataPage";
import { TimeSeriesDataPage } from "pages/TimeSeriesDataPage";
import { DiseaseTrendMainPage } from "pages/DiseaseTrendMainPage";
import { NotFoundPage } from "pages/NotFoundPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/latest-data" replace />} />
      <Route path="/" element={<CommonLayout />}>
        <Route path="/latest-data" element={<LatestDataPage />} />
        <Route path="/graph" element={<DiseaseTrendMainPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/time-series-data" element={<TimeSeriesDataPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
