import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SideMenuData } from "./SideMenuData";
import DiseaseTrends from "./SideMenuDiseaseTrends";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useContext, useState } from "react";
import { SideMenuContext } from "contexts/SideMenuContext";
import { ListItemLinkButton } from "components/SideMenu/ListItemLinkButton";
import { ListItemTopBorder } from "components/SideMenu/ListItemTopBorder";
import LockResetIcon from "@mui/icons-material/LockReset";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAuthenticator } from "@aws-amplify/ui-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const SideMenu = () => {
  const { drawer } = useContext(SideMenuContext);
  const { signOut } = useAuthenticator();
  const [showDiseaseTrends, setShowDiseaseTrends] = useState(true);

  return (
    <Drawer anchor="left" open={drawer.isOpened} onClose={drawer.close}>
      <Box sx={{ width: 250 }} role="presentation">
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <List>
            <>
              {SideMenuData.map((menu, index) => (
                <ListItem key={index} disablePadding>
                  {menu.id !== "graph" ? (
                    <ListItemLinkButton link={menu.link}>
                      <ListItemIcon>{menu.icon}</ListItemIcon>
                      <ListItemText primary={menu.title} />
                    </ListItemLinkButton>
                  ) : (
                    <ListItemButton
                      onClick={() => setShowDiseaseTrends(!showDiseaseTrends)}
                    >
                      <ListItemIcon>{menu.icon}</ListItemIcon>
                      <ListItemText primary={menu.title} />
                      <ListItemIcon sx={{ paddingLeft: 4 }}>
                        {showDiseaseTrends ? <ExpandMore /> : <ExpandLess />}
                      </ListItemIcon>
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
              {showDiseaseTrends && <DiseaseTrends />}
              <ListItemTopBorder>
                <ListItemLinkButton link="/change-password">
                  <ListItemIcon>
                    <LockResetIcon />
                  </ListItemIcon>
                  <ListItemText primary="パスワード変更" />
                </ListItemLinkButton>
              </ListItemTopBorder>
              <ListItemTopBorder>
                <ListItemButton
                  component="button"
                  onClick={() => {
                    signOut();
                    drawer.close();
                  }}
                  sx={{ height: 64 }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="ログアウト" />
                </ListItemButton>
              </ListItemTopBorder>
            </>
          </List>
          <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
      </Box>
    </Drawer>
  );
};
