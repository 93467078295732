import { useContext } from "react";
import { LoadingSpinnerContext } from "contexts/LoadingSpinnerContext";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingSpinner = () => {
  const { isLoading } = useContext(LoadingSpinnerContext);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress size={70} />
    </Backdrop>
  );
};

export default LoadingSpinner;
