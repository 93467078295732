import { createContext, Dispatch, SetStateAction } from "react";

export const SideMenuContext = createContext(
  {} as {
    drawer: {
      isOpened: boolean;
      setIsOpened: Dispatch<SetStateAction<boolean>>;
      close: () => void;
      toggleOpened: (isOpened: boolean) => void;
    };
  },
);
