import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { wrapTableRowData } from "functions";
import { useWindowHeight } from "hooks";
import React from "react";
import { COLOR } from "constants/styleConstants";
import { TableRowData } from "types/latestDataType";
import {
  TABLE_HEIGHT_VH,
  HEADER_HEIGHT_PX,
  ROW_HEIGHT_PX,
  MIN_ROW_COUNT,
  HEADERS,
} from "constants/tableConstants";

export const LatestDataTableForPc = ({
  rowData,
}: {
  rowData: TableRowData[];
}) => {
  const windowHeight = useWindowHeight();
  const tableHeightPx = windowHeight * (TABLE_HEIGHT_VH.LATEST / 100);
  const rowCount = Math.max(
    Math.floor((tableHeightPx - HEADER_HEIGHT_PX) / ROW_HEIGHT_PX),
    MIN_ROW_COUNT,
  );

  const { restructuredData } = wrapTableRowData({ rowData, rowCount });
  const headers = Array(restructuredData[0].length).fill(HEADERS.LATEST).flat();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead
          sx={{
            "& .MuiTableCell-root": {
              backgroundColor: "secondary.main",
              borderRight: "1px solid",
              borderColor: "divider",
            },
          }}
        >
          <TableRow sx={{ height: HEADER_HEIGHT_PX }}>
            {headers.map((header, index) => (
              <TableCell
                key={`${header}-${index}`}
                sx={header ? { whiteSpace: "nowrap" } : { p: 0, minWidth: 6 }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-root": {
              borderRight: "1px solid",
              borderColor: "divider",
            },
          }}
        >
          {restructuredData.map((rows, index) => (
            <React.Fragment key={index}>
              <TableRow
                key={rows[0].name}
                sx={{
                  height: ROW_HEIGHT_PX,
                  "&:last-child td, &:last-child th": { borderBottom: 0 },
                }}
              >
                {rows.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableCell
                      sx={{ p: 0, width: 6, backgroundColor: row.groupColor }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        backgroundColor: COLOR.BACKCOLOR_ITEM_NAME,
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: row.cellColor,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.result}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      {row.normalRange}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {row.comment}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
