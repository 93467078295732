import { DiseaseTrendDataRowType } from "types/diseaseTrendDataRowType";
import { TableRowData } from "types/latestDataType";

type rowDataProps<T> = {
  rowData: T[];
  rowCount: number;
};

export const wrapTableRowData = <
  T extends TableRowData | DiseaseTrendDataRowType,
>(
  props: rowDataProps<T>,
) => {
  const { rowData, rowCount } = props;
  const chunkedRowData: T[][] = [];

  for (let i = 0; i < rowData.length; i += rowCount) {
    chunkedRowData.push(rowData.slice(i, i + rowCount));
  }

  const restructuredData: T[][] = Array.from(
    { length: chunkedRowData[0].length },
    () => [],
  );

  chunkedRowData.forEach((row) => {
    row.forEach((element, index) => {
      restructuredData[index].push(element);
    });
  });

  return { restructuredData };
};
