import { Box, Typography } from "@mui/material";

export const NotFoundPage = () => {
  return (
    <Box sx={{ textAlign: "center", pt: "100px" }}>
      <Typography variant="h1" component="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Page Not Found
      </Typography>
      <Typography variant="body1">
        お探しのページは見つかりませんでした。
      </Typography>
    </Box>
  );
};
