import { Link } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQueryDiseaseTrends } from "hooks/useQueryDiseaseTrends";
import { useContext } from "react";
import { SideMenuContext } from "contexts/SideMenuContext";

const ListItemCenter = styled(ListItem)(() => ({
  textAlign: "center",
  display: "block",
}));

const DiseaseTrends = () => {
  const { drawer } = useContext(SideMenuContext);
  const { isLoading, isError, data } = useQueryDiseaseTrends();

  if (isLoading)
    return (
      <ListItemCenter>
        <CircularProgress />
      </ListItemCenter>
    );
  if (isError) return <ListItem>データの読み込みに失敗しました。</ListItem>;

  return (
    <>
      {data?.items.map((item, i) => {
        return (
          <ListItem key={i} disablePadding>
            <ListItemButton
              component={Link}
              to={`/graph?trend=${item.group_cd}`}
              onClick={drawer.close}
              sx={{ height: 50 }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={item.group_name} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};

export default DiseaseTrends;
