import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@tanstack/react-query";
import { useReverseUserId, fetchWithAlert } from "functions";
import { TimeSeriesData } from "types/timeSeriesDataType";

const fetchTimeSeries = async (personCd: string): Promise<TimeSeriesData> => {
  return await fetchWithAlert(
    `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/time-series`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        person_cd: personCd,
      }),
    },
  );
};

export const useQueryTimeSeries = () => {
  const { user } = useAuthenticator((context) => [context.authStatus]);
  const id = useReverseUserId(user);

  return useQuery({
    queryKey: [`timeSeries${id}`],
    queryFn: () => fetchTimeSeries(user.username),
    staleTime: 60 * 60 * 1000, // NOTE: 1 hour
    enabled: !!user,
  });
};
