import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { COLOR } from "constants/styleConstants";

type Row = {
  groupColor: string;
  name: string;
  result: string;
  normalRange: string;
  comment: string;
  cellColor: string;
};

export const LatestDataTable = ({ rows }: { rows: Row[] }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: { xs: "66svh", sm: "73svh", md: "none" } }}
      >
        <Table size="small" stickyHeader>
          <TableHead
            sx={{
              "& .MuiTableCell-root": {
                backgroundColor: "secondary.main",
                borderColor: "divider",
                "&:not(:last-child)": {
                  borderRight: "1px solid",
                  borderColor: "divider",
                },
              },
            }}
          >
            <TableRow>
              <TableCell sx={{ p: 0, minWidth: 6 }}></TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 10,
                  minWidth: 120,
                }}
              >
                検査項目
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                結果
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                正常範囲
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>備考</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                "&:not(:last-child)": {
                  borderRight: "1px solid",
                  borderColor: "divider",
                },
              },
            }}
          >
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{ p: 0, width: 6, backgroundColor: row.groupColor }}
                ></TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    backgroundColor: COLOR.BACKCOLOR_ITEM_NAME,
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: row.cellColor, whiteSpace: "nowrap" }}
                  align="center"
                >
                  {row.result}
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                  {row.normalRange}
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {row.comment}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
