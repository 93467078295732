import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SideMenu } from "./SideMenu/SideMenu";
import { SideMenuContext } from "contexts/SideMenuContext";
import { useDrawer } from "hooks/useDrawer";
import { HeaderPersonInfo } from "./HeaderPersonInfo";

function Header() {
  const drawer = useDrawer();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={drawer.toggleOpened}
            sx={{ mr: { xs: 0, sm: 2 } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                component="div"
                color="inherit"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                検診データ閲覧システム
              </Typography>
              <HeaderPersonInfo
                sx={{ paddingLeft: { xs: 1, sm: 2, md: 3, lg: 5 } }}
              />
            </Stack>
          </Box>
        </Toolbar>
        <SideMenuContext.Provider value={{ drawer }}>
          <SideMenu />
        </SideMenuContext.Provider>
      </AppBar>
    </Box>
  );
}

export default Header;
