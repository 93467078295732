import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableCellColor } from "functions/tableCellColor";
import { useQueryTimeSeries, useQueryStatusHandler } from "hooks";
import { COLOR } from "constants/styleConstants";

export const TimeSeriesDataPage = () => {
  const { isLoading, isPending, isError, data } = useQueryTimeSeries();
  const message = useQueryStatusHandler({
    isLoading,
    isPending,
    isError,
    paramsData: [data],
  });
  if (message) return message;

  const timeSeries = data!;
  const kdates = timeSeries.columnHeader.map((col) => col.kdate);
  const dataItems = timeSeries.items;

  const rowHeaders = timeSeries.rowHeader.map((row) => ({
    item_name: row.item_name,
    groupColor: row.group_param.split(":")[1],
  }));

  const rowData = rowHeaders.map((row) => {
    return dataItems.flatMap((d) => {
      const item = d.items.find((item) => item.item_name === row.item_name);
      return [
        {
          result: item?.result,
          cellColor: item ? tableCellColor(item.abnormality_flg) : "white",
        },
      ];
    });
  });

  return (
    <>
      <Typography variant="h4" sx={{ mb: 3 }}>
        時系列データ
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "70svh" }}>
          <Table size="small" stickyHeader>
            <TableHead
              sx={{
                "& .MuiTableCell-root": {
                  backgroundColor: "secondary.main",
                  borderColor: "divider",
                  "&:not(:last-child)": {
                    borderRight: "1px solid",
                    borderColor: "divider",
                  },
                },
              }}
            >
              <TableRow>
                <TableCell sx={{ p: 0, minWidth: 6 }}></TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 10,
                    minWidth: 110,
                  }}
                >
                  検査項目
                </TableCell>
                {kdates.map((kdate, index) => (
                  <TableCell key={index} align="center">
                    {kdate}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  "&:not(:last-child)": {
                    borderRight: "1px solid",
                    borderColor: "divider",
                  },
                },
              }}
            >
              {rowHeaders.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{ p: 0, width: 6, backgroundColor: row.groupColor }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: COLOR.BACKCOLOR_ITEM_NAME,
                    }}
                  >
                    {row.item_name}
                  </TableCell>
                  {rowData[index].map((row, index) => {
                    return (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{ backgroundColor: row.cellColor, width: 100 }}
                      >
                        {row.result}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
