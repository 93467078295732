import {
  Authenticator,
  Button,
  Heading,
  Text,
  translations,
  useAuthenticator,
  useTheme,
  View,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { Box, Typography, Link } from "@mui/material";
import { signUp, SignUpInput } from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";
import { TermsModal } from "./TermsModal";
import { useState } from "react";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

I18n.putVocabularies({
  ja: {
    "Create Account": "アカウント登録",
    "Sign In": "ログイン",
    "Sign in": "ログイン",
    "Signing in": "ログイン中",
    "Back to Sign In": "ログイン画面に戻る",
    Username: "ログインID",
    "Enter your Username": "ログインIDを入力",
    "Enter your username": "ログインIDを入力",
    "Change Password": "パスワード変更",
    "Incorrect username or password.":
      "ログインIDまたはパスワードが間違っています",
    "Password must have at least 8 characters":
      "パスワードは8文字以上である必要があります",
    "Password must have upper case letters":
      "パスワードに大文字のアルファベットを含める必要があります",
    "Password must have lower case letters":
      "パスワードに小文字のアルファベットを含める必要があります",
    "Password must have numbers": "パスワードに数字を含める必要があります",
    "Password must have special characters":
      "パスワードに特殊文字を含める必要があります",
    "Your passwords must match": "パスワードが一致しません",
    "Enter your Phone Number": "電話番号を入力",
    "Invalid phone number format.": "無効な電話番号形式です",
    "PreSignUp failed with error Request failed with status code 401.":
      "入力されたログインIDまたは初期パスワードが正しくありません",
    "Invalid verification code provided, please try again.":
      "無効な確認コードが入力されました",
    "Network error": "ネットワークエラーが発生しました",
    "An unknown error has occurred.": "不明なエラーが発生しました",
    "Attempt limit exceeded, please try after some time.":
      "試行回数の制限を超えました。時間をおいてからやり直してください",
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Typography variant="h5" align="center" gutterBottom>
          富士吉田医師会
          <br />
          検診データ閲覧システム
        </Typography>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          登録済みの方はこちら
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            パスワードをお忘れですか？
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`0 0 0 ${tokens.space.xl}`} level={3}>
          初めての方はこちら
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            登録済みの方はこちら
          </Button>
        </View>
      );
    },
    FormFields() {
      const [termsModalOpen, setTermsModalOpen] = useState(false);

      // モーダルを開く
      const handleOpenTermsModal = () => {
        setTermsModalOpen(true);
      };

      // モーダルを閉じる
      const handleCloseTermsModal = () => {
        setTermsModalOpen(false);
      };

      return (
        <>
          <Authenticator.SignUp.FormFields />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckboxField
              name="custom:consent"
              value="on"
              label={
                <Link
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleOpenTermsModal();
                  }}
                  sx={{ cursor: "pointer", ml: 1 }}
                >
                  利用規約
                </Link>
              }
            />
            <span>に同意します</span>
          </Box>
          <TermsModal open={termsModalOpen} onClose={handleCloseTermsModal} />
        </>
      );
    },
  },
};

const formFields = {
  signUp: {
    username: {
      order: 1,
      isRequired: true,
      label: "ログインID (案内書面に記載されています)",
    },
    "custom:initial_password": {
      order: 2,
      isRequired: true,
      label: "初期パスワード (案内書面に記載されています)",
      type: "password",
      placeholder: "初期パスワードを入力",
    },
    email: {
      order: 3,
      label: "メールアドレス (確認コードが送信されます)",
      placeholder: "sample@example.com",
    },
    // phone_number: {
    //   order: 4,
    //   label: "電話番号 (携帯)",
    //   dialCode: "+81",
    //   placeholder: "09001234567",
    // },
    password: {
      order: 5,
      label: "パスワード (ご自身で設定していただくパスワードです)",
      isRequired: true,
    },
    confirm_password: {
      order: 6,
      isRequired: true,
    },
  },
};

const services = {
  async handleSignUp(input: SignUpInput) {
    const { username, password, options } = input;
    const userAttributes = { ...options?.userAttributes };
    const { email, phone_number } = userAttributes;
    const termsAgreed = userAttributes["custom:consent"];

    if (!termsAgreed) {
      throw new Error("利用規約に同意する必要があります");
    }

    if (!email && !phone_number) {
      throw new Error("メールアドレスまたは電話番号を入力してください");
    }

    if (!email) {
      delete userAttributes.email;
    }

    const updatedOptions = { ...options, userAttributes };

    return signUp({
      username,
      password,
      options: updatedOptions,
    });
  },
};

export const Auth = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        minHeight: "100vh",
      }}
    >
      <Authenticator
        components={components}
        formFields={formFields}
        services={services}
      />
    </Box>
  );
};
