import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

interface TermsModalProps {
  open: boolean;
  onClose: () => void;
}

export const TermsModal: React.FC<TermsModalProps> = ({ open, onClose }) => {
  return (
    // Dialogコンポーネントにopenプロパティを設定してモーダルを表示する
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h6" sx={{ textAlign: "center" }}>
        利用規約
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          この利用規約（以下、「本規約」といいます。）は、富士吉田医師会（以下、「当会」といいます。）が
          このウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
          この利用規約に同意することで、本サービスを利用することができます。
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          利用者は、本規約に同意した上で、本サービスを利用するものとします。
          なお、本規約は利用者と当会との間の契約書となります。
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          利用者は、本サービスを利用するにあたり、以下の事項に同意するものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. 利用者は、本サービスを適正に利用するものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          2.利用者は、本サービスを利用するにあたり、法令および公序良俗に違反しないようにするものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.利用者は、本サービスを利用するにあたり、他の利用者や第三者の権利を侵害しないようにするものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          4.
          利用者は、本サービスを利用するにあたり、当会の指示や注意事項に従うものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          5.
          利用者は、本サービスを利用するにあたり、当会のプライバシーポリシーに同意するものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          6.
          利用者は、本サービスを利用するにあたり、当会が提供する情報を正確かつ最新のものとするものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          7.
          利用者は、本サービスを利用するにあたり、自己の責任において行動するものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          8.
          利用者は、本サービスを利用するにあたり、当会の許可なく、第三者に対して本サービスを提供、販売、譲渡、貸与、配信、転送、改変、複製、公衆送信、上映、展示、出版、頒布、翻訳、翻案、二次利用等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          9.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスの一部または全部を改変、逆アセンブル、逆コンパイル、リバースエンジニアリング等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          10.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する商標、ロゴ、デザイン、画像、テキスト等を使用、複製、改変、公開、配布、販売等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          11.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する特許、実用新案、意匠、著作権等を侵害する行為を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          12.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する情報を改竄、消去、破壊、漏洩等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          13.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する情報を不正に取得、収集、抽出、蓄積、利用等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          14.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する情報を第三者に提供、開示、漏洩等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          15.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する情報を商業目的で利用、提供、開示、漏洩等を行わないものとします。
        </Typography>
        <Typography variant="body1" gutterBottom>
          16.
          利用者は、本サービスを利用するにあたり、当会の許可なく、本サービスに関連する情報を他のウェブサイト、アプリケーション等に掲載、リンク、埋め込み等を行わないものとします。
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
