export const TABLE_HEIGHT_VH = {
  // NOTE: テーブル高さ(vh)
  LATEST: 65,
  GRAPH: 60,
} as const;

export const HEADER_HEIGHT_PX = 36.5; // NOTE: 自動調整で36.5px

export const ROW_HEIGHT_PX = 33.02; // NOTE: 自動調整で33.02px

export const MIN_ROW_COUNT = 5;

export const HEADERS = {
  LATEST: ["", "検査項目", "結果", "正常範囲", "備考"],
  GRAPH: ["", "検査項目", "結果"],
} as const;
