import { Box, Typography } from "@mui/material";
import { LatestDataTable } from "../components/LatestDataTable";
import { useQueryLatest } from "hooks/useQueryLatest";
import { tableCellColor } from "functions/tableCellColor";
import { LatestDataTableForPc } from "components/LatestDataTableForPc";
import { useQueryStatusHandler } from "hooks";

export const LatestDataPage = () => {
  const { isLoading, isPending, isError, data } = useQueryLatest();

  const message = useQueryStatusHandler({
    isLoading,
    isPending,
    isError,
    paramsData: [data, data?.items[0]],
  });
  if (message) return message;

  const latestItems = data!.items[0]!;
  const kdate = latestItems.kdate;
  const facility = latestItems.req_name;

  const normalRange = data!.rowHeader.map((row) => {
    if (!row.low && !row.high) {
      return "";
    }
    return `${row.low}〜${row.high}`;
  });

  const rows = latestItems.items
    .filter((item) => !item.item_param.split(",").includes("kensinonly:true"))
    .map((item, index) => {
      return {
        groupColor: item.group_param.split(":")[1],
        name: item.item_name,
        result: item.result,
        normalRange: normalRange ? normalRange[index] : "",
        comment: item.comment_value,
        cellColor: tableCellColor(item.abnormality_flg),
      };
    });

  return (
    <>
      <Typography variant="h4" gutterBottom>
        最新データ
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        検査日：{kdate}
        <br />
        実施施設：{facility}
      </Typography>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <LatestDataTableForPc rowData={rows} />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <LatestDataTable rows={rows} />
      </Box>
    </>
  );
};
