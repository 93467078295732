import { useState } from "react";

export const useDrawer = () => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleOpened = () => setIsOpened(!isOpened);
  const close = () => setIsOpened(false);

  return {
    isOpened,
    setIsOpened,
    close,
    toggleOpened,
  };
};
