import { useQuery } from "@tanstack/react-query";
import { DiseaseTrendItemsAll } from "types/diseaseTrendItemType";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchWithAlert } from "functions";

const fetchDiseaseTrendItemsAll = async (): Promise<DiseaseTrendItemsAll> => {
  return await fetchWithAlert(
    `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/disease-trends-all`,
  );
};

export const useQueryDiseaseTrendItemsAll = () => {
  const { user } = useAuthenticator((context) => [context.authStatus]);

  return useQuery({
    queryKey: ["diseaseTrendItemsAll"],
    queryFn: () => fetchDiseaseTrendItemsAll(),
    staleTime: 1000 * 60 * 60 * 24 * 1, // 1日
    enabled: !!user,
  });
};
