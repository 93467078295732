import { useQuery } from "@tanstack/react-query";
import { DiseaseTrend } from "types/diseaseTrendType";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchWithAlert } from "functions";

const fetchDiseaseTrends = async (): Promise<DiseaseTrend> => {
  return await fetchWithAlert(
    `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/disease-trends`,
  );
};

export const useQueryDiseaseTrends = () => {
  const { user } = useAuthenticator((context) => [context.authStatus]);

  return useQuery({
    queryKey: [`diseaseTrend`],
    queryFn: () => fetchDiseaseTrends(),
    staleTime: 1000 * 60 * 60 * 24 * 1, // 1日
    enabled: !!user,
  });
};
