import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ListItemButton } from "@mui/material";
import { SideMenuContext } from "contexts/SideMenuContext";

type ListItemLinkButtonType = {
  children: React.ReactNode;
  link: string;
};

export const ListItemLinkButton = (props: ListItemLinkButtonType) => {
  const { drawer } = useContext(SideMenuContext);

  return (
    <ListItemButton
      component={Link}
      to={props.link}
      onClick={drawer.close}
      sx={{ height: 64 }}
    >
      {props.children}
    </ListItemButton>
  );
};
