import { Divider, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <footer>
      <Divider variant="middle" />
      <Typography
        variant="body2"
        align="center"
        color="text.secondary"
        sx={{ py: 3 }}
      >
        © 2024 富士吉田医師会
      </Typography>
    </footer>
  );
};
